<template>
  <div class="detail-container">
    <div class="operator-bar">
      <el-button type="primary" @click="openChangePassword">修改密码</el-button>
    </div>
    <div class="detail-info">
      <el-row>
        <el-col :span="3">用户名：</el-col>
        <el-col :span="21">{{info.userName}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3">手机号：</el-col>
        <el-col :span="21">{{info.phone}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3">角色：</el-col>
        <el-col :span="21" v-if="info.role">{{info.role.roleName}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3">描述：</el-col>
        <el-col :span="21">{{info.description}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3">状态：</el-col>
        <el-col :span="21">{{info.userStatus | formatStatus}}</el-col>
      </el-row>
    </div>

    <!-- 修改密码 -->
    <el-dialog :title="dialogConfig.title" :visible.sync="dialogConfig.visible" width="500px">
      <el-form :model="formData" ref="form" :rules="formRules">
        <el-form-item label="新密码：" label-width="100px" prop="password">
          <el-input type="password" v-model="formData.password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" label-width="100px" prop="verifyPassword">
          <el-input type="password" v-model="formData.verifyPassword"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogConfig.visible = false">取 消</el-button>
        <el-button type="primary" :loading="loading == 'submit'" @click="handleChangePassword">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getUserDetail, changePassword} from '@/api/user'

export default {
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入新密码'))
      } else if(value.length < 6 || value.length > 18) {
        callback(new Error('密码为6-18位'))
      } else {
        callback()
      }
    }
    const validateVerifyPassword = (rule, value, callback) => {
      const password = this.formData.password
      if (value == '') {
        callback(new Error('请输入确认密码'))
      } else if (value != password){
        callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }
    return {
      info: {},
      dialogConfig: {
        title: '修改密码',
        visible: false
      },
      formData: {},
      formRules: {
        password: [{ required: true, trigger: 'blur', validator: validatePassword}],
        verifyPassword: [{ required: true, trigger: 'blur', validator: validateVerifyPassword}]
      },
      loading: false
    }
  },
  computed: {
    userId() {
      return this.$store.getters.userinfo.userId
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      getUserDetail({userId:this.userId}).then(res => {
        this.info = res.data
      })
    },
    resetData() {
      this.formData = {
        password: '',
        verifyPassword: ''
      }
    },
    openChangePassword() {
      this.resetData()
      this.dialogConfig.visible = true
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    handleChangePassword() {
      this.$refs.form.validate(valid => {
        if(valid) {
          this.loading = 'submit'
          changePassword({password: this.formData.password,verifyPassword: this.formData.verifyPassword}).then(res => {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.dialogConfig.visible = false
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  },
  filters: {
    // 状态显示转换
    formatStatus(status) {
      const statusMap = {
        'DISABLE': '停用',
        'NORMAL': '正常',
      }
      return statusMap[status]
    }
  }
}
</script>